import { motion } from 'framer-motion';
import { Box } from '@mui/material';
import { MotionConfig } from 'framer-motion';
import FlowTreeSection from './components/FlowTreeSection';
import FlowTree from '../../pages/new_flow_tree/FlowTree';
import Navbar from '../../pages/new_flow_tree/components/Navbar';

export default () => {

	return (
    <motion.div
      style={{ 
        backgroundColor: "#F3F7FF", 
        borderRadius: "30px", 
        width: "100vw",
        // height: "calc(100vh - 64px)",
        height: "100vh",
        paddingTop: "86px",
        flex: 2,
        overflow: "hidden",
        position: "relative",
      }}
      exit={{ opacity: 0 }}
      layout
    >
      <Navbar />
      <Box sx={{
        height: "calc(100% - 64px)"
      }}>
        <FlowTree />	
        </Box>
    </motion.div>
  )
}
