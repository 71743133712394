import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import AnimWrapperFromHome from '../../global_components/Animated/AnimWrapperFromHome';
// import FlowTree from './FlowTree';
import FlowTree from '../new_flow_tree/FlowTree';
import Navbar from './components/Navbar';

export default () => {

  // NOTES
  // The white space is the stylesRightBlock in AnimWrapperFromHome

  const Placeholder = () => {
    return (
      <Box sx={{ backgroundColor: 'blue', width: '100%', height: '100%' }}>
        Placeholder Component
      </Box>
    );
  };

  return (
    <Box>
      <AnimWrapperFromHome duration={0.45} delay={450} height="calc(100% - 50px)">
      {/* <Box sx={{ */}
      {/*   width: '100%', */}
      {/*   height: '100vh' */}
      {/* }}> */}
      {/*   <Box sx={{ */}
      {/*     width: '100%', */}
      {/*     overflow: 'hidden', */}
      {/*     position: 'relative', */}
      {/*     height: "calc(100% - 50px)", */}
      {/*   }}> */}
          <Navbar />
          <Box sx={{
            height: "calc(100% - 64px)",
            width: "100%",
          }}>
            <FlowTree />
          </Box>
      {/*   </Box> */}
      {/* </Box> */}
      </AnimWrapperFromHome>
    </Box>
  )}
